/* global config, angular */

'use strict';

(function () {
    function AuthService($rootScope, $window, $location, $cookies, $q, http, Util, User, clients, $_DevicesManagers) {
        var safeCb = Util.safeCb;
        var currentUser = {};
        var userRoles = ['admin', 'user'];
        if ($cookies.get(config.auth.token) && $location.path() !== '/logout') {
            try {
                currentUser = JSON.parse($cookies.get(config.auth.me));
            } catch (ex) {
                if ($location.path() !== '/login') {
                    $location.path('/login/');
                }
            }
        }

        function clearAllCookies(clearAll = false, fromLogout = false) {
            let cookies = $cookies.getAll();
            angular.forEach(cookies, (v, k) => {
                if (clearAll === true) {
                    $cookies.remove(k);
                } else {
                    if (k !== config.tmsPopup) {
                        $cookies.remove(k);
                    }
                }
            });
        }

        var Auth = {
            setCurrentUser(user = null, fromCookie = false) {
                if (user === null) {
                    if (fromCookie === true) {
                        try {
                            currentUser = JSON.parse($cookies.get(config.auth.me));
                        } catch (ex) {
                        }
                    }
                } else {
                    currentUser = Object.assign({}, user);
                }//
            },
            login(email, password) {
                return new Promise((resolve, reject) => {
                    let url = config.websiteURL + '/auth/local';
                    if (config.isPartnerSession) {
                        if (config.isDevicesManagerSession === true) {
                            url += '?dm=1';
                        } else {
                            url += '?c=1&p=1';
                        }
                    }
                    http.post(url, {
                        email: email,
                        password: password
                    }).then((res) => {
                        clearAllCookies();
                        $cookies.put(config.auth.token, res.data.token);
                        if (config.isPartnerSession) {
                            if (config.isDevicesManagerSession === true) {
                                $cookies.put('session_type', 'devices-manager');
                                return $_DevicesManagers.get('me').then((user) => {
                                    return Promise.resolve(user);
                                });
                            } else {
                                $cookies.put('session_type', 'partner');
                                return clients.me().then((user) => {
                                    return Promise.resolve(user);
                                });
                            }
                        }
                        return User.get().$promise;
                    }).then((user) => {
                        if (user) {
                            currentUser = user;
                            $rootScope.currentUser = currentUser;
                            $cookies.put(config.auth.me, JSON.stringify(user));
                            resolve(user);
                        } else {
                            reject();
                        }
                    }).catch((err) => {
                        if (err.data) {
                            reject(err.data);
                        } else {
                            reject(err);
                        }
                    });
                });
            },
            logout() {
                clearAllCookies();
                localStorage.clear();
                currentUser = {};
                $rootScope.currentUser = {};
                $rootScope.loggedOut();
            },
            createUser(user, callback) {
                return User.save(user, function (data) {
                    $cookies.put(config.auth.token, data.token);
                    currentUser = User.get();
                    return safeCb(callback)(null, user);
                }, function (err) {
                    Auth.logout();
                    return safeCb(callback)(err);
                }).$promise;
            },
            changePassword(oldPassword, newPassword, callback) {
                return User.changePassword({
                    id: currentUser._id
                }, {
                    oldPassword: oldPassword,
                    newPassword: newPassword
                }, function () {
                    return safeCb(callback)(null);
                }, function (err) {
                    return safeCb(callback)(err);
                }).$promise;
            },
            getCurrentUser(callback) {
                if (!$cookies.get(config.auth.token)) {
                    let currentPath = $location.path();                    
                    if (currentPath !== '/login') {                        
                        if (currentPath.indexOf('/devices-manager') === 0) {
                            config.isDevicesManagerSession = true;
                            $cookies.put('session_type', 'devices-manager');
                        }
                        $location.path('/login');
                    }
                    return null;
                }
                if (arguments.length === 0) {
                    return currentUser;
                }
                var value = currentUser.hasOwnProperty('$promise')
                        ? currentUser.$promise
                        : currentUser;
                return $q.when(value)
                        .then(user => {
                            safeCb(callback)(user);
                            return user;
                        }, () => {
                            safeCb(callback)({});
                            return {};
                        });
            },
            isLoggedIn(callback) {
                if (arguments.length === 0) {
                    if(config.isPartnerSession === true){
                        return currentUser.hasOwnProperty('_id');    
                    }
                    return currentUser.hasOwnProperty('role');
                }
                return Auth.getCurrentUser(null).then(user => {
                    var is = user.hasOwnProperty('role');
                    safeCb(callback)(is);
                    return is;
                });
            },
            hasRole(role, callback) {
                var hasRole = function (r, h) {
                    return userRoles.indexOf(r) >= userRoles.indexOf(h);
                };
                if (arguments.length < 2) {
                    return hasRole(currentUser.role, role);
                }
                return Auth.getCurrentUser(null).then(user => {
                    var has = user.hasOwnProperty('role') ? hasRole(user.role, role) : false;
                    safeCb(callback)(has);
                    return has;
                });
            },
            hasPermission(permissionId = null, permissionMethod = null, permissions = []) {
                if (permissionId === null || permissionMethod === null) {
                    return;
                }
                if (typeof permissionId !== 'string' || typeof permissionMethod !== 'string') {
                    return;
                }
                permissionId = permissionId.toUpperCase();
                permissionMethod = permissionMethod.toUpperCase();
                let user = Auth.getCurrentUser();
                if (permissions.length === 0) {
                    permissions = JSON.parse((user || {}).permissions || null);
                }
                for (let i = 0; i < permissions.length; i++) {
                    const permission = permissions[i].split(':'),
                            _permissionId = permission[0].toUpperCase(),
                            _permissionMethod = permission[1].toUpperCase();
                    if (_permissionId === '*' || _permissionId === permissionId) {
                        if (_permissionMethod === '*' || _permissionMethod === permissionMethod) {
                            return true;
                        }
                    }
                }//
                return false;
            },
            hasPermissions(permissions = null, user) {
                if (!permissions) {
                    return;
                }
                if (typeof permissions === 'string') {
                    permissions = [permissions];
                }
                user = user || Auth.getCurrentUser() || {};
                let userPermissions = JSON.parse(user.permissions || null),
                        hasPermissions = new Array(permissions.length);
                hasPermissions.fill(false);
                if (!userPermissions || userPermissions.length === 0) {
                    return;
                }
                for (let i = 0; i < permissions.length; i++) {
                    const permission = permissions[i].split(':'),
                            permissionId = permission[0],
                            permissionMethod = permission[1];
                    for (let j = 0; j < userPermissions.length; j++) {
                        const userPermission = userPermissions[j].split(':'),
                                userPermissionId = userPermission[0],
                                userPermissionMethod = userPermission[1];
                        if (userPermissionId === '*' || userPermissionId === permissionId) {
                            if (userPermissionMethod === '*' || userPermissionMethod === permissionMethod) {
                                return true;
                            }
                        }
                    }
                }
                return hasPermissions.every((v) => {
                    return v;
                });
            },
            isAdmin() {
                return Auth.hasRole.apply(Auth, [].concat.apply(['admin'], arguments));
            },
            getToken() {
                return $cookies.get(config.auth.token);
            }
        };
        return Auth;
    }
    angular.module('managerApp.auth').factory('Auth', AuthService);
})();
